$red: #d82236;

$blue: #001155;
$blue-1: #1f4485;
$blue-1-5: #19376b;
$blue-2: #002a74;
$blue-2-5: #1b438d;
$blue-3: #1a171b;
$blue-light: #1781e3;
$blue-light-1: #268def;
$blue-light-2: #00a3fd;
$blue-light-2-2: #dde3e7;
$blue-light-2-5: #d1e6f9;
$blue-light-3: #bcf0ff;
$blue-light-3-5: #a2cdf4;
$blue-light-4: #0082ea;
$blue-light-5: #0050e2;
$blue-ultra-light: #cceeff;

$blue-accessible: #086ADB;

$grey: #333333;
$grey-light: #6c6c6c;
$grey-another-one: #999999;
$grey-medium: #bbbbbb;
$grey-dark: #1a171b;
$grey-ultra-light: #e1e2e3;
$grey-ultra-light-2: #f3f3f3;
$grey-another-one-2: #666666;
$grey-another-one-3: #d6d6d6;
$grey-another-one-4: #f0f0f0;
$grey-another-one-5: #f5f5f5;
$grey-another-one-6: #666666;
$grey-another-one-7: #f1f1f1;
$grey-another-one-8: #d7d7d7;
$grey-another-one-9: #edf3f6;
$grey-another-one-10: #dce3e7;
$grey-another-one-11: #cfd5d9;
$grey-another-one-12: #eef3f6;
$grey-another-one-13: #e4e9ec;
$grey-another-one-14: #f8fafb;

$white: #ffffff;
$black: #000000;

$hover-color: $grey-another-one-5;
$active-color: $grey-another-one-12;

// kpis
$kpi-modal-arrow-fill-color: $blue-light-4;
$kpi-modal-arrow-background-hover: $grey-another-one-9;
$kpi-modal-arrow-background-active: $grey-another-one-10;
$kpis-card-separator-color: $black;
$kpis-background-color: rgba($grey-dark, 0.24);
$kpis-background-color-hover: $blue-light-4;

$all-colors: (
  'red': $red,
  'blue': $blue,
  'blue-1': $blue-1,
  'blue-1-5': $blue-1-5,
  'blue-2': $blue-2,
  'blue-2-5': $blue-2-5,
  'blue-3': $blue-3,
  'blue-light': $blue-light,
  'blue-light-1': $blue-light-1,
  'blue-light-2': $blue-light-2,
  'blue-light-2-2': $blue-light-2-2,
  'blue-light-2-5': $blue-light-2-5,
  'blue-light-3': $blue-light-3,
  'blue-light-3-5': $blue-light-3-5,
  'blue-light-4': $blue-light-4,
  'blue-light-5': $blue-light-5,
  'blue-ultra-light': $blue-ultra-light,
  'blue-accessible': $blue-accessible,
  'grey': $grey,
  'grey-light': $grey-light,
  'grey-another-one': $grey-another-one,
  'grey-medium': $grey-medium,
  'grey-dark': $grey-dark,
  'grey-ultra-light': $grey-ultra-light,
  'grey-ultra-light-2': $grey-ultra-light-2,
  'grey-another-one-2': $grey-another-one-2,
  'grey-another-one-3': $grey-another-one-3,
  'grey-another-one-4': $grey-another-one-4,
  'grey-another-one-5': $grey-another-one-5,
  'grey-another-one-6': $grey-another-one-6,
  'grey-another-one-7': $grey-another-one-7,
  'grey-another-one-8': $grey-another-one-8,
  'grey-another-one-9': $grey-another-one-9,
  'grey-another-one-10': $grey-another-one-10,
  'grey-another-one-11': $grey-another-one-11,
  'grey-another-one-12': $grey-another-one-12,
  'grey-another-one-13': $grey-another-one-13,
  'grey-another-one-14': $grey-another-one-14,
  'white': $white,
  'black': $black,
  'hover-color': $hover-color,
  'active-color': $active-color,
  'kpi-modal-arrow-fill-color': $kpi-modal-arrow-fill-color,
  'kpi-modal-arrow-background-hover': $kpi-modal-arrow-background-hover,
  'kpi-modal-arrow-background-active': $kpi-modal-arrow-background-active,
  'kpis-card-separator-color': $kpis-card-separator-color,
  'kpis-background-color': $kpis-background-color,
  'kpis-background-color-hove': $kpis-background-color-hover
);

@each $color-name in map-keys($map: $all-colors) {
  $color-value: map-get($map: $all-colors,
    $key: $color-name );

  .#{$color-name} {
    color: $color-value !important;
  }
}